import { render, staticRenderFns } from "./SuccessStoryLanding.vue?vue&type=template&id=2cdacf7c&scoped=true"
import script from "./SuccessStoryLanding.vue?vue&type=script&lang=js"
export * from "./SuccessStoryLanding.vue?vue&type=script&lang=js"
import style0 from "./SuccessStoryLanding.vue?vue&type=style&index=0&id=2cdacf7c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cdacf7c",
  null
  
)

export default component.exports