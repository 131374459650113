<template>
  <div class="about">
    <!-- Page Title
	============================================= -->
    <section id="page-title" class="page-title-parallax include-header" style="
        padding: 250px 0;
        background-image: url('/assets/images/landing/customersuccess.jpg');
        background-size: cover;
        background-position: center center;
      " data-bottom-top="background-position:0px 400px;" data-top-bottom="background-position:0px -500px;">
      <div class="container clearfix">
        <h1>Customer Success</h1>
      </div>
    </section>
    <!-- #page-title end -->

    <!-- Content
	============================================= -->
    <section id="content">
      <div class="content-wrap">
        <div class="container clearfix">
          <div class="row col-mb-50 mb-0">
            <div class="col-lg-6" style="padding-left: 4em">
              <div class="heading-block fancy-title border-bottom-0 title-bottom-border">
                <h4>
                  Why did Affinity choose eFlex as their outsourcing partner?
                </h4>
              </div>
              <p>
                <i>
                  Affinity was looking for a partner to help them grow their
                  customer service team and offer longer hours for customer
                  service. After meeting with eFlex's leadership team, they
                  chose eFlex to help them. Since then, they've doubled the
                  number of people on their customer service team and continue
                  to have a high customer satisfaction rating (CSAT) of 95%.
                </i>
              </p>
            </div>

            <div class="col-lg-6" style="padding-left: 4em">
              <img src="/assets/images/landing/AaronAffinity.png" alt="" />
            </div>
          </div>
        </div>
        <!-- Affinity Success START -->
        <div class="container clearfix">
          <div class="clear"></div>
          <div class="heading-block">
            <h4>The Story of Affinity</h4>
            <p>
              <i>
                Affinity is a relationship intelligence platform built to expand
                and evolve traditional CRMs. They have a mission to continue
                building the future of relationship intelligence and
                revolutionize how businesses manage and leverage their networks.
                The Affinity platform is used by over 2,000 relationship-driven
                organizations around the world. Founded in 2014, Affinity is
                headquartered in San Francisco and backed by leading investors,
                including Menlo Ventures, Advance Venture Partners, 8VC, and
                MassMutual Ventures. Affinity has recently raised $80M in Series
                C funding and has expanded its office locations to Canada and
                the UK.
              </i>
            </p>
            <h4>
              Looking to scale customer support before partnering with eFlex
            </h4>
            <p>
              <i>
                <a href="https://www.linkedin.com/in/aaron-shaw-uleary-37938653/">
                  Aaron Shaw-Uleary
                </a>
                , the Senior Director of Customer Support at Affinity, managed
                their customer support team in San Francisco. As Affinity's
                success continued to expand, so did its client base. Because of
                this, Aaron wanted to grow his team to keep providing excellent
                customer service and add more customer service hours to serve
                customers worldwide.
              </i>
            </p>
            <h4>Why Affinity Chose eFlex as their outsourcing partner</h4>
            <p>
              <i>
                "At first, I was skeptical about engaging an offshore vendor for
                Affinity's growing support operation,” stated Aaron. “Would the
                vendor be able to provide talent comparable to the Bay Area?"
                Would English as a second language be a concern? Would the
                representatives guard our brand reputation as if our company and
                customers were their own? That said, after visiting the eFlex
                leadership team, I decided to try them."
              </i>
            </p>
            <h4>Launching Affinity and Eflex's Pilot Program</h4>
            <p>
              <i>
                Many of eFlex's new partners start with a small "pioneer team"
                to help launch the pilot program and then scale the team with
                additional agents. The pioneer team or agent is a small group or
                an individual who will come on board to learn the program and
                then train and implement best practices as new agents join the
                team.
              </i>
            </p>
            <p>
              <i>
                "I was still a little apprehensive; however, the relationship
                started modestly with one pioneer agent. eFlex and I partnered
                closely on a hiring profile that would meet our organization's
                unique needs. In short, they identified a highly skilled
                representative with a Bachelor of Secondary Education. I was
                highly impressed with the agent's attention to detail, English
                proficiency, and ability to grasp SaaS best practices. My
                confidence in eFlex grew tremendously."
              </i>
            </p>
            <h4>The Results</h4>
            <p>
              <i>
                Like many of eFlex's pilot programs, the launch was successful,
                and since then, Affinity's offshore team has grown. eFlex agents
                also train new Affinity team members, whether in the Philippines
                or internal employees for Affinity, allowing Aaron and the
                leadership team to focus on their core business objectives.
              </i>
            </p>
            <p>
              <i>
                "Fast forward to today, and I can confidently say that eFlex has
                become integral to our service model with over a dozen agents
                and counting. Even better, we were able to scale gracefully
                while maintaining an amazing customer satisfaction rating. As
                eFlex's brand reputation began to grow across my organization,
                other departments took notice. Our Finance team now leverages
                eFlex agents for a range of complex accounting tasks while
                Affinity's Engineering team was able to ramp up an effective QA
                team by leveraging eFlex's access to highly skilled engineers.”
              </i>
            </p>
            <p>
              <i>
                eFlex values collaboration and transparency. Because these
                values are ingrained in eFlex's culture, we can develop strong
                relationships with our partners, just like with Affinity. After
                partnering with eFlex, Affinity has been able to scale their
                support team and offer around-the-clock support for their
                clients without impacting their CSAT scores.
              </i>
            </p>
          </div>
        </div>


        <!-- Affinity Success END -->

        <section class="p-2 container">
          <Calendly />
        </section>
        <!-- <section>
          <div class="content-wrap">
            <div class="container clearfix">
              <div class="row col-mb-80 landing">
                <div class="postcontent col-lg-9">
                  <div class="single-post mb-0">
                    <div class="card">
                      <div class="card-header"><strong>About Rob</strong></div>
                      <div class="card-body">
                        <div class="author-image">
                          <img
                            src="/assets/images/author/rob.jpg"
                            alt="Image"
                            class="rounded-circle" />
                        </div>
                        <p>
                          <i>
                            <a href="https://www.linkedin.com/in/robastudillo/">
                              Rob
                            </a>
                            is the CEO of eFlexervices. For more than 10 years,
                            he has been building powerful business partnerships
                            with US companies by building high-performing
                            offshore teams that help optimize operations and
                            build cost saving solutions to enable growth, scale,
                            and optimization
                          </i>
                        </p>
                        <div class="callbuttondiv">
                          <router-link to="/affinity-success/calendly">
                            <a class="callbutton button-large nott ls0 center">
                              Book A Call with eFlex's CEO, Rob
                            </a>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> -->

        <!-- Client Section -->
        <div class="container clearfix">
          <div class="clear"></div>

          <div class="heading-block center">
            <h4>Our Clients</h4>
          </div>

          <ul class="clients-grid grid-2 grid-sm-4 grid-md-3 mb-0">
            <li class="grid-item">
              <img src="/assets/images/clients/aqqire.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/manscaped.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/cvent.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/iscribe.jpeg" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/copper.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/brightwheel.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/upcity.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/uservoice.png" alt="Clients" />
            </li>
            <li class="grid-item">
              <img src="/assets/images/clients/affinity.jpg" alt="Clients" />
            </li>
          </ul>
        </div>
      </div>
    </section>
    <!-- #content end -->
  </div>
</template>

<script>
import Calendly from '../components/Calendly.vue';

export default {
  data() {
    return {}
  },
  mounted() {
    window.scrollTo(0, 0)
  },
  components: {
    Calendly
  }
}
</script>

<style scoped>
#page-title {
  padding-top: 110px;
}

/* .callbutton {
    display: inline-block;
    position: relative;
    cursor: pointer;
    outline: none;
    white-space: nowrap;
    margin-left: 20px;
    background-color: #1abc9c;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 10px 30px;
    font-size: 1.2rem;
    line-height: 40px;
    margin-top: 2em;
  }

  @media screen and (min-width: 769px) {
    .landing {
      margin-left: 23%;
    }
    .callbuttondiv {
      text-align: center;
    }
  }

  @media screen and (max-width: 480px) {
    .callbutton {
      display: inline-block;
      position: relative;
      cursor: pointer;
      outline: none;
      white-space: nowrap;
      background-color: #1abc9c;
      color: #fff;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 1px;
      border: none;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      padding: 10px 20px;
      font-size: 1rem;
      line-height: 40px;
      margin-top: 2em;
    }
  } */
</style>
